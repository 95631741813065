import { A } from "@solidjs/router";
import { plutoLogoName } from "~/assets/assets";
import { HubbleEvent, webEventManager } from "~/data/events";
import { Spacer } from "~/widgets/spacer";

export default function PlutoStrip() {
  return (
    <A
      href="https://universe.myhubble.money/pluto"
      target="_blank"
      onClick={() => {
        webEventManager.sendEvent(HubbleEvent.CLICK_PLUTO_STRIP);
      }}
    >
      <div class="bg-plutoStripBgMob lg:bg-plutoStripBgLG flex cursor-pointer flex-row items-center justify-between bg-cover bg-no-repeat px-4 py-2 font-inter md:justify-center lg:bg-contain lg:px-5 ">
        <div class="flex flex-row items-center">
          <NewButton />
          <Spacer width={8} />
          <img
            src={plutoLogoName}
            alt=""
            class="h-7 w-[84px] cursor-pointer lg:h-8 lg:w-[87px]"
          />
          <div class="block lg:hidden">
            <Spacer width={2.8} />
          </div>
          <div class="hidden lg:block">
            <Spacer width={4} />
          </div>
          <span class="cursor-pointer text-nowrap text-f12 text-white lg:text-medium">
            : Thoughtful gifting with AI’s magic
          </span>
        </div>
        <Spacer width={4} />
        <ArrowRight />
      </div>
    </A>
  );

  function NewButton() {
    return (
      <div
        class="flex items-center  rounded-md border border-white bg-[#E0153E] px-1.5 py-0.5 "
        style={{
          "box-shadow": "0px 2px 3px 0px rgba(152, 95, 76, 0.13)",
        }}
      >
        <span class="text-f12w700  tracking-[0.96px] text-baseTertiaryLight">
          NEW
        </span>
      </div>
    );
  }

  function ArrowRight() {
    return <i class={"ph-bold ph-arrow-right text-[16px] text-white"}></i>;
  }
}
