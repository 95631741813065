// import { RandomEmojiFaces } from "../brand_l2/trust_marker_happy_users";

export const SocialProofing = () => {
  return (
    <div class="mx-4 mt-10 rounded-2xl border border-neutral-200 bg-baseTertiaryLight p-1 shadow-base lg:mx-auto lg:max-w-[1136px]">
      <div class="flex flex-col rounded-xl border border-gray-100 bg-gradient-to-r from-amber-50 to-baseTertiaryLight px-6 py-4 md:flex-row">
        <div class="mb-3 border-b border-baseTertiaryMedium sm:hidden">
          {/* <RandomEmojiFaces /> */}
        </div>
        <div class="flex flex-col gap-3 sm:flex-row">
          <div class="flex items-center gap-2">
            <span class="text-h4 lg:text-h3">5L+</span>
            <span class="text-bold">Happy Hubble users</span>
          </div>
          <div class="flex items-center gap-2">
            <span class="text-h4 text-successDark lg:text-h3">₹10Cr</span>
            <span class="text-bold lg:inline-block">
              Saved till date with gift cards
            </span>
          </div>
        </div>
        <div class="hidden md:ml-auto md:flex">
          {/* <RandomEmojiFaces /> */}
        </div>
      </div>
    </div>
  );
};
