import { giftCardPurple } from "~/assets/assets";
import { TrustMakerStrip } from "../groups/content";
import { AboutGiftCard } from "../about_gift_card";
import { useModal } from "../modal";

export function HomeHubbleHeader(props: { scrollToElement?: () => void }) {
  const { openModal } = useModal()!;

  return (
    <div>
      <div class="flex py-8 text-center">
        <h1 class="mx-auto flex flex-col text-[28px] font-semibold text-textDark md:text-h1 lg:text-[48px]">
          <span class="my-0 text-successDark md:my-3">
            Enjoy instant discount
          </span>
          <div class="flex flex-col gap-1 md:flex-row">
            <span class="text-basePrimaryDark">on 400+ brands with</span>
            <div class="m-auto flex gap-1">
              <img
                class="h-8 self-center md:h-11"
                src={giftCardPurple}
                alt="gift-card"
              />
              <span class="inline-block bg-gradient-to-br from-graidentPurple to-graidentBlue bg-clip-text px-1 text-h3 text-transparent md:text-h1 lg:text-[48px]">
                Gift Cards
              </span>
            </div>
          </div>
        </h1>
      </div>

      <div class="mx-auto flex justify-center gap-4 px-4">
        <button
          onClick={props.scrollToElement}
          class="my-2 flex h-11 w-[176px] items-center justify-center rounded-full bg-basePrimaryDark py-3 text-center text-buttonMedium text-baseTertiaryLight disabled:bg-gray-400"
        >
          Explore Brands
        </button>
        <button
          onClick={() => {
            openModal(() => {
              return <AboutGiftCard />;
            });
          }}
          class="text-baseDark hover:border-baseDark my-2 flex h-11 w-[176px] items-center justify-center rounded-full border border-baseTertiaryDark bg-baseTertiaryLight py-3 text-buttonMedium hover:border-b disabled:bg-gray-400"
        >
          What's a gift card?
        </button>
      </div>

      <TrustMakerStrip />
    </div>
  );
}
