import {
  occasionalSpends,
  piggyBankIcon,
  routineSpends,
  seasonalSpends,
} from "~/assets/assets";

export const ValueCommunication = () => {
  return (
    <div class="rounded-2xl border p-1 md:mx-auto md:my-6 md:max-w-[1136px]">
      <div class="grid grid-cols-1 items-center gap-4 rounded-2xl border border-[#DAE7E1] bg-gradient-to-r from-[rgba(55,212,130,0.1)] to-[rgba(255,255,255,0.1)] md:grid-cols-4">
        <div class="flex flex-col  items-start p-6 pr-0 md:col-span-1 md:items-baseline">
          <img src={piggyBankIcon} class="text-5xl "></img>
          <p class="mt-4 text-h3 text-successDark md:text-center">Save ~</p>
          <p class="text-h2 font-semibold text-green-700 md:text-center">
            ₹20k+ in a year
          </p>
        </div>

        <div class="grid grid-cols-1 md:col-span-3 md:grid-cols-3">
          <div class="flex flex-col justify-between border-t border-[#DAE7E1] p-6 md:border-l md:border-t-0">
            <div>
              <p class="text-h3 text-textDark">Save ₹10k+</p>
              <p class="text-normal text-textDark">
                on routine spends of avg. ₹12k per month
              </p>
            </div>
            <div class="mt-4">
              <p class="text-medium text-textNormal">
                Food · Groceries · Commute
              </p>
              <div class="mt-2 flex space-x-2">
                <img src={routineSpends} alt="" />
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-between border-t border-[#DAE7E1] p-6 md:border-l md:border-t-0">
            <div>
              <p class="text-h3 text-textDark">Save ₹5k+</p>
              <p class="text-normal text-textDark">
                on seasonal spends of avg. ₹18k per quarter
              </p>
            </div>
            <div class="mt-4">
              <p class="text-medium text-textNormal">
                Food · groceries · Commute
              </p>
              <div class="mt-2 flex space-x-2">
                <img src={seasonalSpends} alt="" />
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-between border-t border-[#DAE7E1] p-6 md:border-l md:border-t-0">
            <div>
              <p class="text-h3 text-textDark">Save ₹10k+</p>
              <p class="text-normal text-textDark">
                on occasional spends of avg. ₹90k in a year
              </p>
            </div>
            <div class="mt-4">
              <p class="text-medium text-textNormal">
                Jewellery · Gifting · Electronics
              </p>
              <div class="mt-2 flex space-x-2">
                <img src={occasionalSpends} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
