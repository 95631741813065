import { StarRow, coinIcon } from "~/assets/assets";
import { CoinsTransactionsModal } from "~/components/coins_transactions_modal";
import { toIndianNumber } from "~/utils/number";
import { useModal } from "~/components/modal";
import { Accessor, Match, Setter, Show, Switch } from "solid-js";

type HomeRnrHeaderProps = {
  name: string;
  observerRef?: HTMLDivElement;
  coinsAvailable: number;
  setRefreshCoins: Setter<boolean>;
  isClientAxis: Accessor<boolean | undefined>;
};

export function HomeRnrHeader(props: HomeRnrHeaderProps) {
  const { openModal } = useModal()!;

  return (
    <div
      class={`mx-3 flex flex-col items-center justify-center rounded-b-[24px] p-4 md:bg-whiteBg  md:py-0 lg:mt-8 ${props.isClientAxis() ? "bg-homeAxisMobileGradient" : "bg-homepageMobileGradient"}`}
    >
      <div
        class={`top-0 flex flex-col items-center justify-center bg-contain bg-center bg-no-repeat lg:h-[300px] lg:w-[950px] ${props.isClientAxis() ? "lg:mt-8" : "lg:bg-homeRNRHeaderBg"}`}
      >
        <Show when={props.name}>
          <div
            class={`pb-1 text-f12Bold tracking-[7%] ${props.isClientAxis() ? "text-axisPink" : "text-[#8B651B] lg:text-orange"}`}
          >
            {props.name.toLocaleUpperCase()}
          </div>
        </Show>
        <div class="flex items-center gap-2">
          <StarRow
            starSizes={[2, 3.5]}
            fillColor="#E47B00"
            altFillColor="#8B651B"
            isClientAxis={props.isClientAxis}
            breakpoint="lg"
          />
          <div
            class={`font-sacramento text-h1 font-normal tracking-[0px] ${props.isClientAxis() ? "text-axisPink" : "text-[#8B651B] lg:text-orange"}`}
          >
            Congratulations
          </div>
          <StarRow
            starSizes={[3.5, 2]}
            fillColor="#E47B00"
            altFillColor="#8B651B"
            isClientAxis={props.isClientAxis}
            breakpoint="lg"
          />
        </div>
        <div
          ref={props.observerRef}
          class="pb-4 text-center text-h4 lg:pb-6 lg:text-h1"
        >
          <Switch
            fallback={
              <>
                Shop on 400+ brands with <br /> your coins
              </>
            }
          >
            <Match when={props.isClientAxis()}>
              Redeem your coins to shop
              <br />
              on these brands
            </Match>
          </Switch>
        </div>
        <div>
          <div
            onClick={(event) => {
              event.stopPropagation();
              openModal(() => {
                return (
                  <CoinsTransactionsModal
                    setRefreshCoins={props.setRefreshCoins}
                    balance={props.coinsAvailable}
                    userName={props.name}
                    enableRedeemCode={true}
                    title="Your coins"
                    subtitle="Use it to get gift cards of your choice"
                  />
                );
              });
            }}
            class={`flex cursor-pointer flex-row items-center rounded-[50px] border bg-baseTertiaryLight p-2  pr-3 text-mediumBold ${props.isClientAxis() ? "lg:mb-2" : "lg:mb-10"}`}
            style={{
              "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
              border: "0.5px solid rgba(192, 192, 192, 1)",
            }}
          >
            <div class="pr-[6px]">
              <img src={coinIcon} class="h-6 w-6" />
            </div>
            Balance: {toIndianNumber(props.coinsAvailable)}
          </div>
        </div>
      </div>
    </div>
  );
}
