import {
  Accessor,
  createEffect,
  onCleanup,
  onMount,
  Setter,
  Show,
} from "solid-js";
import { coinIcon } from "~/assets/assets";
import { toIndianNumber } from "~/utils/number";
import { Spacer } from "~/widgets/spacer";
import { useModal } from "../modal";
import { CoinsTransactionsModal } from "../coins_transactions_modal";
import { Mode } from "~/types";
import { isMobile } from "~/utils/platform";

export function CoinsBalancePill(props: {
  latestCoinsBalance?: Accessor<number | undefined>;
  mode: Mode;
  setRefreshLatestBalance: Setter<boolean>;
  userName?: string;
  isMobile?: boolean;
  setShowCoinsPillOnHeader: Setter<boolean>;
  startObserve: Accessor<boolean>;
}) {
  let observer: IntersectionObserver;
  let observerRef: HTMLDivElement | undefined = undefined;

  onMount(() => {
    if (props.isMobile && !isMobile()) {
      return;
    }

    observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        props.setShowCoinsPillOnHeader(false);
      } else {
        props.setShowCoinsPillOnHeader(true);
      }
    });
    onCleanup(() => {
      if (observerRef) {
        observer.unobserve(observerRef);
      }
    });
  });

  createEffect(() => {
    if (props.startObserve()) {
      if (observerRef) {
        if (props.isMobile && !isMobile()) {
          return;
        }
        observer.observe(observerRef);
      }
    }
  });

  const { openModal, setIsModalOn, updateModalContentGetter } = useModal()!;
  return (
    <div
      ref={observerRef}
      onClick={() => {
        updateModalContentGetter(() => {
          return (
            <CoinsTransactionsModal
              balance={props.latestCoinsBalance?.() ?? 0}
              userName={props.userName ?? ""}
              setRefreshCoins={props.setRefreshLatestBalance}
              enableRedeemCode={false}
              title={props.mode === "rnr" ? "Your coins" : undefined}
              subtitle={
                props.mode === "rnr"
                  ? "Use it to get gift cards of your choice"
                  : undefined
              }
            />
          );
        });

        setIsModalOn(true);
      }}
      class=" z-10 cursor-pointer rounded-full border border-[#313538] bg-baseLight px-4 py-[9.5px] hover:border-[#ABABAB]"
    >
      <span class="flex flex-row items-center justify-center text-nowrap text-f12Bold uppercase text-baseDark sm:text-[14px]">
        YOU HAVE
        <Spacer width={6} />
        <img src={coinIcon} alt="" class="h-4 w-4" />
        <Spacer width={2} />
        <span class="font-bold text-rewardsYellow">
          {toIndianNumber(props.latestCoinsBalance?.() ?? 0)}
        </span>
        <Spacer width={6} />
        {"COINS"}
      </span>
    </div>
  );
}

export function Name(props: { name: string }) {
  return (
    <span class="text-f12Bold uppercase text-[#FF3689] sm:text-[16px]">
      <Show when={props.name}>👋 &nbsp{`${props.name}`}</Show>
      <Show when={!props.name}>WELCOME</Show>
    </span>
  );
}

export function getGiftCardText() {
  return (
    <div class="z-10 flex w-full flex-row items-center justify-center gap-4 px-5 sm:px-[156px]">
      {buildArrow()}
      <span class="text-nowrap text-normal text-basePrimaryMedium">
        Get gift card(s) from choice of brands
      </span>
      <div class="w-full rotate-180">{buildArrow()}</div>
    </div>
  );

  function buildArrow() {
    return (
      <div
        class="h-[1px] w-full "
        style={{
          "border-radius": "2px",
          background:
            "linear-gradient(270deg, #313538 0%, rgba(49, 53, 56, 0.00) 100%)",
        }}
      >
        &nbsp;
      </div>
    );
  }
}
